<template>
  <v-flex xs12>
    <v-text-field label="Search"
                  solo
                  dense
                  single-line
                  clearable
                  clear-icon="far fa-times-circle"
                  prepend-inner-icon="fas fa-search"
                  v-model="search"
                  @change="getSearchItems"
                  @input="getSearchItems"
                  @clear-icon-cb="getSearchItems">

      <template slot="append">
          <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                  <v-btn v-on="on" @click.prevent="addClick(add = true)"
                         slot="activator"
                         small
                         fab
                         text
                         style="padding: 0; margin: 0;">
                      <v-icon>fas fa-plus</v-icon>
                  </v-btn>
              </template>
              <span>{{addMessage}}</span>
          </v-tooltip>
      </template>
    </v-text-field>
     
</v-flex>
</template>
<script>
    import { isNullEmptyOrUndefined } from '../../javascript/globalFunctions'

    export default {
        props: { addButtonMessage: String},
        data() {
          return {
              search: '',
              addMessage: "Add Item"
          }
        },
        methods: {
            getSearchItems: function () {
                this.$emit('gSearch', this.search)
            },
            addClick: function (add) {
                this.$emit('add', add)
            },
            checkAddButtonMessage: function () {
                if (!isNullEmptyOrUndefined(this.addButtonMessage)) {
                    this.addMessage = this.addButtonMessage
                }
            }
        },
        beforeMount() {
            this.checkAddButtonMessage()
        }
  }
</script>

<style scope>
  .v-input__slot {
    border-radius: 95px !important;
  }
</style>
